import { StatusBar } from "expo-status-bar";
import { useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  auth,
  getUserDetails,
  logout,
  onAuthStateChanged,
} from "./firebaseConfig";

import useCachedResources from "./hooks/useCachedResources";
import Navigation from "./navigation";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const [userInstance, setuserInstance] = useState(null);
  const [userDetails, setuserDetails] = useState(null);
  const [isAuthLoadingComplete, setisAuthLoadingComplete] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        (async () => {
          const localUserDetails = await getUserDetails();
          setuserDetails(localUserDetails);
        })();
      }
      setuserInstance(user);
      setisAuthLoadingComplete(true);
    });
  }, []);

  if (!isLoadingComplete || !isAuthLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation user={userInstance} userDetails={userDetails} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}
