import {
  FlatList,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { auth, getUserDetails, logout } from "../../firebaseConfig";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  allShiftsState,
  getAllShiftsState,
  setAllShiftsState,
} from "../../navigation/state";

type Props = {};

function timeConverter(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp * 1000).toDateString();
  return a;
}

const Item = ({ details }) => {
  return (
    <View
      style={{
        marginTop: 20,
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      {!details.bold ? (
        <Text style={[{ flex: 1 }]}>{timeConverter(details.date.seconds)}</Text>
      ) : (
        <Text style={[{ flex: 1 }, { fontWeight: 800 }]}>{details.date}</Text>
      )}
      <Text style={[{ flex: 1 }, details.bold && { fontWeight: 800 }]}>
        {details.hours}
      </Text>
      <Text style={[{ flex: 1 }, details.bold && { fontWeight: 800 }]}>
        {details.jobName}
      </Text>
    </View>
  );
};

const Home = (props: Props) => {
  // const [allShifts, setAllShifts] = useState(null);
  const navigation = useNavigation();
  // const route = useRoute();
  const allShifts = allShiftsState.use();

  const [unpaidShifts, setunpaidShifts] = useState([]);
  const [unpaidHours, setunpaidHours] = useState(0);
  const [paidShifts, setpaidShifts] = useState([]);

  const renderItem = ({ item }) => <Item details={item} />;

  useEffect(() => {
    getAllShiftsState();
  }, []);

  useEffect(() => {
    const localunpaidShifts = [];
    let localunpaidHours = 0;
    const localpaidShifts = [];
    (async () => {
      const userDetails = await getUserDetails();
      const lastPayDate = userDetails.lastPayDate;

      if (lastPayDate) {
        allShifts.forEach((shift) => {
          //if shift.date is before lastPayDate then push to localpaidShifts
          if (shift.date.seconds < lastPayDate.seconds) {
            localpaidShifts.push({
              date: shift.date,
              hours: shift.hours,
              jobName: shift.jobName,
            });
          }
          //if shift.date is after lastPayDate then push to localunpaidShifts
          else {
            localunpaidShifts.push({
              date: shift.date,
              hours: shift.hours,
              jobName: shift.jobName,
            });
            localunpaidHours += shift.hours;
          }
        });
      } else {
        allShifts.forEach((shift) => {
          localunpaidShifts.push({
            date: shift.date,
            hours: shift.hours,
            jobName: shift.jobName,
          });
          localunpaidHours += shift.hours;
        });
      }

      setpaidShifts(localpaidShifts);
      setunpaidShifts(localunpaidShifts);
      setunpaidHours(localunpaidHours);
    })();
  }, [allShifts]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Unpaid Hours {unpaidHours}</Text>
      <FlatList
        style={{
          maxHeight: "40%",
          width: "90%",
          marginTop: 10,
          alignSelf: "center",
          backgroundColor: "white",
          padding: 20,
        }}
        data={unpaidShifts}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
      />
      <Text style={styles.title}>Paid Hours</Text>
      <FlatList
        style={{
          maxHeight: "40%",
          width: "90%",
          marginTop: 10,
          alignSelf: "center",
          backgroundColor: "white",
          padding: 20,
        }}
        data={paidShifts}
        renderItem={renderItem}
        keyExtractor={(item, index) => index}
      />
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          alignSelf: "center",
        }}
      >
        <TouchableOpacity
          style={{
            width: 120,
            height: 60,
            padding: 10,
            borderRadius: 6,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "blue",
            alignSelf: "center",
          }}
          onPress={() => navigation.navigate("AddNewShift")}
        >
          <Text
            style={{
              fontSize: 15,
              color: "white",
            }}
          >
            Add New Shift
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: 120,
            height: 60,
            padding: 10,
            borderRadius: 6,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "blue",
          }}
          onPress={logout}
        >
          <Text
            style={{
              fontSize: 15,
              color: "white",
            }}
          >
            Logout
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
