import { initializeApp } from "firebase/app";

// Optionally import the services that you want to use
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
//import {...} from "firebase/database";
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  collection,
  where,
  getDocs,
  query,
} from "firebase/firestore";
import { setAllShiftsState } from "./navigation/state";
//import {...} from "firebase/functions";
//import {...} from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAoyZ2xMFFxtYAig-Ik3J4Dc91BFLZLcAU",
  authDomain: "micton-hours.firebaseapp.com",
  projectId: "micton-hours",
  storageBucket: "micton-hours.appspot.com",
  messagingSenderId: "399556515281",
  appId: "1:399556515281:web:1c42be3e32f27a3bee7cf0",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

const login = ({ email, password }) =>
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
    });

const signUpUser = ({ email, password, name }) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
    });

const getUserDetails = async () => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

const addNewShift = async (shiftDetails) => {
  console.log(shiftDetails);
  await addDoc(
    collection(db, "users", auth.currentUser.uid, "shifts"),
    shiftDetails
  );
};

const getShifts = async () => {
  let allShiftsList = [];
  const docRef = collection(db, "users", auth.currentUser.uid, "shifts");
  const q = query(docRef);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    allShiftsList.push(doc.data());
  });

  return allShiftsList;
};

const logout = () => signOut(auth);

//For admin side
const getAllEmployeeShifts = async () => {
  let allShiftsList = [];
  const docRef = collection(db, "users");
  const q = query(docRef, where("name", "!=", null));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((userDoc) => {
    //get each users shifts
    (async () => {
      const docRef = collection(db, "users", userDoc.id, "shifts");
      const q = query(docRef);

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.name = userDoc.data().name;
        data.userId = userDoc.id;
        data.docId = doc.id;
        allShiftsList.push(data);
      });
    })();
    //
  });
  return allShiftsList;
};

const getJobLocations = async () => {
  let allJobsList = [];
  const docRef = collection(db, "jobs");
  const q = query(docRef);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((userDoc) => {
    if (userDoc.data().completed !== true) {
      //get each users shifts
      allJobsList.push({ label: userDoc.data().label, value: userDoc.value });
      //
    }
  });
  return allJobsList;
};
//

export {
  getJobLocations,
  auth,
  onAuthStateChanged,
  login,
  getUserDetails,
  signUpUser,
  logout,
  addNewShift,
  getShifts,
  getAllEmployeeShifts,
};
