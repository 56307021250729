import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { getAllEmployeeShifts, logout } from "../../firebaseConfig";

type Props = {};

const HomeAdmin = (props: Props) => {
  const [employeeShifts, setemployeeShifts] = useState([]);
  useEffect(() => {
    (async () => {
      const localEmployeeShifts = await getAllEmployeeShifts();
      setemployeeShifts(localEmployeeShifts);
    })();
  }, []);

  // useEffect(() => {
  //   console.log(employeeShifts);
  // }, [employeeShifts]);

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.txt}>Home</Text>
        <Text>Hello You Are The Admin! Welcome</Text>

        {employeeShifts.length > 0 &&
          employeeShifts.map((shift) => {
            console.log(shift);
            return (
              <View key={shift.userId} style={{ borderWidth: 2 }}>
                <Text>{shift.name}</Text>
                <Text>{shift.date}</Text>
                <Text>{shift.startTime}</Text>
                <Text>{shift.endTime}</Text>
                <Text>{shift.jobLocation}</Text>
              </View>
            );
          })}

        <TouchableOpacity style={styles.btn} onPress={logout}>
          <Text style={styles.btnText}>Logout</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default HomeAdmin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  section: {
    width: "50%",
    height: "50%",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  textInput: {
    width: "100%",
    height: 50,
    padding: 10,
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 15,
  },
  btn: {
    width: "100%",
    height: 50,
    padding: 10,
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "blue",
  },
  btnText: {
    fontSize: 15,
    color: "white",
  },
  txt: {
    fontSize: 15,
    color: "black",
  },
});
