import {
  Button,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { login } from "../../firebaseConfig";

type Props = {};

const Login = (props: Props) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text>Micton Hours Manager</Text>
        <TextInput
          placeholder="Email"
          style={styles.textInput}
          value={email}
          onChangeText={(val) => setemail(val)}
          autoCapitalize={"none"}
          autoCorrect={false}
        />
        <TextInput
          placeholder="Password"
          style={styles.textInput}
          value={password}
          onChangeText={(val) => setpassword(val)}
          autoCapitalize={"none"}
          autoCorrect={false}
        />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => login({ email, password })}
        >
          <Text style={styles.btnText}>Login</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  section: {
    width: "50%",
    height: "50%",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  textInput: {
    width: "100%",
    height: 50,
    padding: 10,
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 15,
  },
  btn: {
    width: "100%",
    height: 50,
    padding: 10,
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "blue",
  },
  btnText: {
    fontSize: 15,
    color: "white",
  },
});
