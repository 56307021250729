import { Modal, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import * as React from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Select from "react-select";
import { addNewShift, getJobLocations, logout } from "../../../firebaseConfig";
import { useNavigation } from "@react-navigation/native";
import { getAllShiftsState } from "../../../navigation/state";

const AddNewShift = () => {
  const [date, setdate] = React.useState<Dayjs | null>(null);
  const [startTime, setstartTime] = React.useState<Dayjs | null>(null);
  const [endTime, setendTime] = React.useState<Dayjs | null>(null);
  const [selectedJob, setselectedJob] = React.useState<string | null>(null);
  const [jobOptions, setjobOptions] = React.useState([]);

  const navigation = useNavigation();

  // const jobOptions = [
  //   { value: "tonystore", label: "Tony's Store" },
  //   { value: "ashurmahbub", label: "Ashur Guelph" },
  //   { value: "ashurrebbeca", label: "Ashur Cambridge" },
  // ];

  const handleGetJobLocations = async () => {
    const jobLocations = await getJobLocations();
    setjobOptions(jobLocations);
  };

  React.useEffect(() => {
    handleGetJobLocations();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          alignSelf: "center",
          padding: 10,
        }}
      >
        <TouchableOpacity
          style={{
            width: 120,
            height: 60,
            padding: 10,
            borderRadius: 6,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "blue",
          }}
          onPress={() => navigation.navigate("Home")}
        >
          <Text
            style={{
              fontSize: 15,
              color: "white",
            }}
          >
            X
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: 120,
            height: 60,
            padding: 10,
            borderRadius: 6,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "blue",
            alignSelf: "center",
          }}
          onPress={() => {
            if (startTime < endTime && selectedJob && date) {
              addNewShift({
                date: date?.toDate(),
                startTime: startTime["$H"] * 60 + startTime["$m"],
                endTime: endTime["$H"] * 60 + endTime["$m"],
                jobName: selectedJob.label,
                hours:
                  Math.round(
                    ((endTime["$H"] * 60 +
                      endTime["$m"] -
                      (startTime["$H"] * 60 + startTime["$m"])) /
                      60) *
                      10
                  ) / 10,
              });
              getAllShiftsState();
              navigation.navigate("Home");
              setdate(null);
              setstartTime(null);
              setendTime(null);
              setselectedJob(null);
            } else {
              console.log("try again end time cannot earlier than start");
            }
          }}
        >
          <Text
            style={{
              fontSize: 15,
              color: "white",
            }}
          >
            ✓
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          //justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          padding: 10,
        }}
      >
        <View style={{ width: "100%", padding: 5 }}>
          <Text style={{ marginBottom: 30 }}>Enter New Shift Details</Text>
          <DesktopDatePicker
            label="Date"
            disableFuture={true}
            inputFormat="MM/DD/YYYY"
            value={date}
            onChange={(newValue) => setdate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </View>
        <View style={{ width: "100%", padding: 5 }}>
          <TimePicker
            label="Start Time"
            value={startTime}
            onChange={(newValue) => setstartTime(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </View>
        <View style={{ width: "100%", padding: 5 }}>
          <TimePicker
            label="End Time"
            value={endTime}
            onChange={(newValue) => setendTime(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </View>
        <View style={{ width: "100%", padding: 5 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="Job Name"
            defaultValue={selectedJob}
            onChange={setselectedJob}
            options={jobOptions}
          />
        </View>
      </View>
    </LocalizationProvider>
  );
};

export default AddNewShift;
