import { entity } from "simpler-state";
import { getShifts } from "../firebaseConfig";

export const allShiftsState = entity([]);

export const setAllShiftsState = (val) => {
  allShiftsState.set(val);
};

export const getAllShiftsState = () => {
  (async () => {
    const localAllShifts = await getShifts();
    localAllShifts.sort(function (x, y) {
      return y.date - x.date;
    });
    // localAllShifts.unshift({
    //   date: "Date",
    //   hours: "Hours",
    //   jobName: "Job Name",
    //   bold: true,
    // });
    setAllShiftsState(localAllShifts);
    // setAllShifts(localAllShifts);
  })();
};
